import React from "react";
import cx from "classnames";
import { Link } from "gatsby";
import { useSetActiveTags } from "src/context/siteContext";
import { ReactComponent as Logotype } from "src/images/svg/Logotype.svg";

import styles from "./logo.module.css";

export const HeaderLogo = ({ className }) => {
  const setActiveTags = useSetActiveTags();
  // const handleClick = e => {
  //   e.preventDefault();
  //   setActiveTags([]);

  //   window.scrollTo({ top: 0 });
  // };
  return (
    <div className={cx("bg-white", styles.headerLogo, className)}>
      <Link to="/" aria-label="You Too Can Woo Homepage">
        <Logotype />
      </Link>
    </div>
  );
};
