import React from "react";
import cx from "classnames";
import {
  FaTwitter,
  FaFacebookSquare,
  FaVimeoV,
  FaInstagram,
} from "react-icons/fa";

import * as styles from "./social-links.module.css";

export const SocialLinks = ({ className, social }) => {
  return (
    <ul className={cx(styles.socialLinks, className)}>
      {social.twitter && (
        <li>
          <a
            className={cx(styles.socialLink)}
            href={social.facebook}
            target="_blank"
            aria-label="Twitter"
            rel="noreferrer nofollow"
          >
            <FaTwitter />
          </a>
        </li>
      )}
      {social.facebook && (
        <li>
          <a
            className={cx(styles.socialLink)}
            href={social.facebook}
            target="_blank"
            aria-label="Facebook"
            rel="noreferrer nofollow"
          >
            <FaFacebookSquare />
          </a>
        </li>
      )}
      {social.vimeo && (
        <li>
          <a
            className={cx(styles.socialLink)}
            href={social.vimeo}
            target="_blank"
            aria-label="Vimeo"
            rel="noreferrer nofollow"
          >
            <FaVimeoV />
          </a>
        </li>
      )}
      {social.instagram && (
        <li>
          <a
            className={cx(styles.socialLink)}
            href={social.instagram}
            target="_blank"
            aria-label="Instagram"
            rel="noreferrer nofollow"
          >
            <FaInstagram />
          </a>
        </li>
      )}
    </ul>
  );
};
