import React from "react";
import cx from "classnames";
import { Image } from "src/components/image";

import styles from "./images.module.css";


export const BlockImages = ({ images }) => {
  return (
    <div className={styles.images}>
      <div className={styles.inner}>
        {images &&
          images.map(image => {
            return (
              <div className={styles.imageContainer}>
                <Image
                  className={styles.image}
                  imageId={image.asset._id}
                  alt={image.alt}
                  style={{
                    maxHeight: "600px",
                    margin: "0 auto",
                  }}
                  objectFit="contain"
                />
                <p className={cx("caption", styles.caption)}>{image.caption}</p>
              </div>
            );
          })}
      </div>
    </div>
  );
};
