import React from "react";
import useVH from "react-viewport-height";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as Toucan } from "src/images/svg/CursorToucan.svg";

import "./loading.css";

export const Loading = ({ isLoaded = false }) => {
  useVH();
  return (
    <CSSTransition
      in={!isLoaded}
      classNames="loading-"
      timeout={2000}
      unmountOnExit
    >
      <div
        className="loading"
        // style={{ height: `calc(var(--vh, 1vh) * 100)` }}
      >
        <div className="loading__toucan-container">
          <Toucan className="loading__toucan" />
        </div>
      </div>
    </CSSTransition>
  );
};
