import React from "react";
import cx from "classnames";
import { ThemeProvider } from "@material-ui/core/styles";
import { Modal, Slide } from "@material-ui/core";

import { Backdrop } from "src/components/backdrop";
import ScrollLock from "react-scrolllock";
import { Ticker } from "src/components/ticker";

import {
  useToggleAffirmations,
  useStoreContext,
} from "src/context/siteContext";
import { useAffirmationsData } from "src/hooks/useAffirmationsData";
import { ReactComponent as CloseX } from "src/images/svg/CloseX.svg";

import { theme } from "src/utils/createMuiTheme";

import * as styles from "./affirmations-drawer.module.css";

export const AffirmationsDrawer = () => {
  const { affirmationsIsOpen } = useStoreContext();
  const toggleAffirmations = useToggleAffirmations();
  const {
    consolidatedAffirmations,
  } = useAffirmationsData();

  function shuffle(array) {
    let currentIndex = array.length;
    let temporaryValue;
    let randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={affirmationsIsOpen}
        onClose={toggleAffirmations}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          active: affirmationsIsOpen,
          toggle: toggleAffirmations,
        }}
      >
        <Slide
          direction="up"
          in={affirmationsIsOpen}
          timeout={{
            enter: 600,
            exit: 300,
          }}
        >
          <div
            className={cx("bg-dark-gray c-white", styles.drawer)}
            role="presentation"
          >
            <button
              className={cx("reset", styles.closeButton)}
              onClick={toggleAffirmations}
            >
              <CloseX />
            </button>
            <ScrollLock>
              <div className={styles.drawerContent}>
                <div className={styles.affirmations}>
                  {consolidatedAffirmations?.length && (
                    <>
                      <h2
                        className={cx("subheading2", styles.affirmationTitle)}
                      >
                        Daily Affirmations
                      </h2>
                      {[...Array(100)].map((x, i) => (
                        <div
                          key={`ticker-${i}`}
                          className={styles.affirmationRow}
                        >
                          <Ticker
                            items={shuffle([
                              ...consolidatedAffirmations,
                            ]).splice(2)}
                            itemClassName={cx("body")}
                            randomSymbols={true}
                          />
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </ScrollLock>
          </div>
        </Slide>
      </Modal>
    </ThemeProvider>
  );
};
