import React from "react";
import SanityBlockContent from "@sanity/block-content-to-react";
import { Link } from "src/components/link";
import { getInternalLinkPath } from "./getInternalLinkPath";
import { BlockImages } from "src/components/block-content/images";

const ListSerializer = ({ type, children }) => {
  return type === "bullet" ? <ul>{children}</ul> : <ol>{children}</ol>;
};

const ListItemSerializer = ({ children }) => {
  return <li>{children}</li>;
};

export const Serializer = {
  list: ListSerializer,
  listItem: ListItemSerializer,

  types: {
    block: props => {
      switch (props.node.style) {
        case "label":
          return <span className="label-caps">{props.children}</span>;
          break;
        case "italicLabel":
          return <span className="label-italic">{props.children}</span>;
          break;
        case "headlineRegular":
          return <span className="headline">{props.children}</span>;
          break;
        case "headlineItalic":
          return <span className="headline-italic">{props.children}</span>;
          break;
        case "headlineCaps":
          return <span className="headline-caps">{props.children}</span>;
          break;
        case "pullQuote":
          return <blockquote className="headline">{props.children}</blockquote>;
          break;
        default:
          return SanityBlockContent.defaultSerializers.types.block(props);
      }
    },

    blockImageModule: props => {
      return <BlockImages images={props.node.images} />;
    },
    spacer: () => {
      return <div className="spacer" />;
    },
  },
  marks: {
    uppercase: props => <span className="label-caps">{props.children}</span>,
    tick: props => <span className="tick">{props.children}</span>,
    link: ({ mark, children }) => {
      const { blank, href } = mark;
      return blank ? (
        <a href={href} target="_blank" rel="noopener">
          {children}
        </a>
      ) : (
        <a href={href}>{children}</a>
      );
    },
    internalLink: ({ mark, children }) => {
      const path =
        mark?.reference?.link && getInternalLinkPath(mark.reference.link);

      if (!path) {
        return null;
      }
      return (
        <Link type="internalLink" to={path}>
          {children}
        </Link>
      );
    },
  },
};
