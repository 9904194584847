import React from "react";
import { StoreContextProvider } from "src/context/siteContext";
import "intersection-observer";

export const wrapRootElement = ({ element }) => (
  <StoreContextProvider>{element}</StoreContextProvider>
);

export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  // console.log(location);
  if (location.action === "PUSH") {
    if (location.state.shouldUpdateScroll === false) {
      if (window.INDEX_SCROLL_POSITION) {
        window.scrollTo(0, window.INDEX_SCROLL_POSITION);
      } else {
        return false;
      }
    } else {
      window.scrollTo(0, 0);
    }
  } else {
    const savedPosition = getSavedScrollPosition(location);
    window.scrollTo(...(savedPosition || [0, 0]));
  }
  return false;
};

export const onInitialClientRender = () => {
  window.___INITIAL_RENDER_COMPLETE = true;
};

// This only works if in the modal page you add a
// Link to the homepage.
export const onPrefetchPathname = ({ loadPage }) => {
  if (window.indexPageData === undefined) {
    loadPage("/").then(result => {
      window.indexPageData = result;
      // If we load the modal page directly we need to
      // re-render by changing the state. Otherwise
      // the initial result is null for some reason.
      if (window.setIndexPageData) window.setIndexPageData();
    });
  }
};

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (prevLocation !== null) {
    const skipLink = document.querySelector("#reach-skip-nav");
    if (skipLink) {
      skipLink.focus();
    }
  }
};
