import React from "react";
import cx from "classnames";
import { ThemeProvider } from "@material-ui/core/styles";
import { Modal, Slide } from "@material-ui/core";
import { Backdrop } from "src/components/backdrop";
import ScrollLock from "react-scrolllock";
import { BlockContent } from "src/components/block-content";
import { SocialLinks } from "src/components/social-links";
import { Ticker } from "src/components/ticker";
import { useToggleContact, useStoreContext } from "src/context/siteContext";
import { useContactData } from "src/hooks/useContactData";
import { ReactComponent as CloseX } from "src/images/svg/CloseX.svg";
import { ReactComponent as Toucan } from "src/images/svg/LogoToucan.svg";

import { theme } from "src/utils/createMuiTheme";
import * as styles from "./contact-drawer.module.css";

export const ContactDrawer = () => {
  const { contactIsOpen } = useStoreContext();
  const toggleContact = useToggleContact();
  const { contact, social, affirmations } = useContactData();

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={contactIsOpen}
        onClose={toggleContact}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          active: contactIsOpen,
          toggle: toggleContact,
        }}
      >
        <Slide
          direction="left"
          in={contactIsOpen}
          timeout={{
            enter: 600,
            exit: 300,
          }}
        >
          <div
            className={cx("bg-dark-gray c-white", styles.drawer)}
            role="presentation"
          >
            <button
              className={cx("reset", styles.closeButton)}
              onClick={toggleContact}
            >
              <CloseX />
            </button>
            <ScrollLock>
              <div className={styles.drawerContent}>
                <div className={styles.contact}>
                  <div className={styles.contactLogoColumn}>
                    <Toucan className={styles.contactToucan} />
                  </div>
                  <div className={styles.contactDetailsColumn}>
                    <div className={styles.contactDetailsContainer}>
                      <h2 className={cx("heading6 c-white")}>
                        {contact.headline}
                      </h2>
                      <BlockContent
                        className={styles.contactCopy}
                        blocks={contact.copy}
                      />
                      <div className={styles.contactDetails}>
                        <span className="block">{contact.contactName}</span>
                        <a
                          className={cx("block", styles.contactLink)}
                          href={`mailto:${contact.contactEmail}`}
                        >
                          {contact.contactEmail}
                        </a>
                        <a
                          className={cx("block", styles.contactLink)}
                          href={`tel:${contact.contactPhoneNumber}`}
                        >
                          {contact.contactPhone}
                        </a>
                      </div>
                    </div>
                    <div className={styles.socialLinksContainer}>
                      <h2
                        className={cx("subheading2", styles.socialLinksPrompt)}
                      >
                        Connect with us
                      </h2>
                      <SocialLinks
                        className={styles.socialLinks}
                        social={social}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles.affirmations}>
                  {affirmations?.length && (
                    <>
                      <h2
                        className={cx("subheading2", styles.affirmationTitle)}
                      >
                        Daily Affirmations
                      </h2>
                      {affirmations.map(
                        (category) => (
                          <div
                            className={styles.affirmationCategory}
                            key={category._key}
                          >
                            <span
                              className={cx(
                                "subheading2",
                                styles.affirmationTitle
                              )}
                            >
                              {category.category}
                            </span>
                            <Ticker
                              randomSymbols={false}
                              items={category.affirmations}
                              itemClassName={cx("body3")}
                            />
                          </div>
                        )
                      )}
                    </>
                  )}
                </div>
              </div>
            </ScrollLock>
          </div>
        </Slide>
      </Modal>
    </ThemeProvider>
  );
};
