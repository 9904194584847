import React from "react";
import cx from "classnames";
import SanityBlockContent from "@sanity/block-content-to-react";
import { Serializer } from "src/utils/serializer";

export const BlockContent = ({
  blocks,
  layout,
  className,
}) => {
  if (!blocks) {
    // console.error("Missing blocks");
    return null;
  }

  return (
    <div className={cx("block-content", layout, className)}>
      <SanityBlockContent blocks={blocks} serializers={Serializer} />
    </div>
  );
};
