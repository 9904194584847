import { useStaticQuery, graphql } from "gatsby";
export const useContactData = () => {
  const {
    sanityContact,
    sanitySiteGlobal,
    sanityAffirmations,
  } = useStaticQuery(
    graphql`
      query ContactQuery {
        sanityContact {
          copy: _rawCopy(resolveReferences: { maxDepth: 4 })
          contactEmail
          contactName
          contactPhone
          contactPhoneNumber
          headline
        }
        sanitySiteGlobal {
          content {
            social: _rawSocial(resolveReferences: { maxDepth: 2 })
          }
        }
        sanityAffirmations {
          affirmationCategories: _rawAffirmationCategories(
            resolveReferences: { maxDepth: 4 }
          )
        }
      }
    `
  );

  return {
    contact: sanityContact,
    social: sanitySiteGlobal.content.social,
    affirmations: sanityAffirmations.affirmationCategories,
  };
};
