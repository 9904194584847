import React from "react";
import cx from "classnames";
import { useToggleMenu } from "src/context/siteContext";
import styles from "./hamburger.module.css";


export const Hamburger = ({ className, open }) => {
  const toggleMenu = useToggleMenu();
  return (
    <button
      onClick={() => toggleMenu()}
      className={cx(
        styles.hamburger,
        styles.hamburgerSqueeze,
        open && styles.isActive,
        className
      )}
      type="button"
      aria-label="Toggle Main Menu"
      aria-haspopup="true"
      aria-expanded={open}
    >
      <span className={styles.hamburgerBox}>
        <span className={styles.hamburgerInner} />
      </span>
    </button>
  );
};
