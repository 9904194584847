import React, { useState } from "react";
import cx from "classnames";
import { useLocation } from "@reach/router";
import { Collapse } from "@material-ui/core";
import { Link } from "gatsby";
import { Hamburger } from "src/components/hamburger";
import {
  useStoreContext,
  useToggleMenu,
  useSetActiveTags,
  useToggleContact,
  useToggleInstrument,
} from "src/context/siteContext";

import * as styles from "./mobile-menu.module.css";

export const MobileMenu = () => {
  const location = useLocation();
  const { menuIsOpen, instrumentIsOpen } = useStoreContext();
  const setActiveTags = useSetActiveTags();
  const toggleContact = useToggleContact();
  const toggleMenu = useToggleMenu();
  const toggleInstrument = useToggleInstrument();

  return (
    <div className={styles.mobileMenu}>
      <div className={styles.toggle}>
        <div className={styles.currentPage}>
          <ul>
            <li>
              <Link
                className={styles.currentPageLink}
                to={location.pathname}
                onClick={() => {
                  if (instrumentIsOpen) {
                    toggleInstrument();
                    if (menuIsOpen) {
                      toggleMenu();
                    }
                  } else {
                    toggleMenu();
                  }
                }}
                getProps={({ isPartiallyCurrent }) => {
                  return isPartiallyCurrent && location.pathname != "/about"
                    ? {
                        className: cx(
                          styles.currentPageLink,
                          styles.visible,
                          instrumentIsOpen ? "" : styles.active
                        ),
                      }
                    : {};
                }}
              >
                <span className="heading5">Our Work</span>
              </Link>
            </li>
            <li>
              <Link
                className={styles.currentPageLink}
                to="/about"
                onClick={instrumentIsOpen ? toggleInstrument : toggleMenu}
                activeClassName={cx(styles.visible, styles.active)}
              >
                <span className="heading5">Who We Are</span>
              </Link>
            </li>
          </ul>
        </div>
        <Hamburger className={styles.hamburger} open={menuIsOpen} />
      </div>
      <Collapse in={menuIsOpen}>
        <div className={cx(styles.collapse)} role="presentation">
          <div className={styles.collapseInner}>
            <ul>
              <li>
                <Link
                  className={styles.menuLink}
                  to="/"
                  onClick={() => {
                    instrumentIsOpen && toggleInstrument();
                    toggleMenu();
                    setActiveTags([]);
                  }}
                  getProps={({ isPartiallyCurrent }) => {
                    return isPartiallyCurrent && location.pathname != "/about"
                      ? {
                          className: cx(styles.menuLink, styles.active),
                        }
                      : {};
                  }}
                >
                  <span className="heading5">Our Work</span>
                </Link>
              </li>
              <li>
                <Link
                  className={styles.menuLink}
                  to="/about"
                  onClick={() => {
                    instrumentIsOpen && toggleInstrument();
                    toggleMenu();
                  }}
                  activeClassName={styles.active}
                >
                  <span className="heading5">Who We Are</span>
                </Link>
              </li>
              <li>
                <button
                  className={styles.menuButton}
                  onClick={() => {
                    toggleMenu();
                    toggleContact();
                  }}
                >
                  <span className="heading5">Contact</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Collapse>
    </div>
  );
};
