import React, { useState } from "react";
import cx from "classnames";

import { ReactComponent as Notes } from "src/images/svg/Notes.svg";
import { ReactComponent as Cord } from "src/images/svg/Cord.svg";
import { ReactComponent as Wave } from "src/images/svg/Wave.svg";

import styles from "./ticker.module.css";

export const Ticker = ({
  className,
  itemClassName,
  items,
  randomSymbols = true,
}) => {
  const characterCount = items.reduce((count, item) => {
    return (count += item.length);
  }, 0);

  const groupCount = 4;
  const tickerStyle = {
    "--speed": `${characterCount / 3}s`,
    "--move-initial": `${-100 / groupCount}%`,
    "--move-final": `${(-100 / groupCount) * 2}%`,
  };

  return (
    <div className={cx(styles.ticker, className)} style={tickerStyle}>
      <div className={styles.inner}>
        {[...Array(4)].map((itemGroup, loopIndex) => {
          return (
            <span className={styles.itemGroup} key={`items-group-${loopIndex}`}>
              {items &&
                items.map((item, itemIndex) => {
                  const randomNumber = Math.floor(
                    Math.random() * Math.floor(20)
                  );
                  const Symbol =
                    randomSymbols && randomNumber <= 1
                      ? Cord
                      : randomSymbols && randomNumber <= 3
                      ? Notes
                      : randomSymbols && randomNumber <= 5
                      ? Wave
                      : null;
                  return (
                    <span
                      key={`items-group-${loopIndex}-item-${itemIndex}`}
                      className={cx(
                        styles.item,
                        Symbol ? "" : styles.asterisk,
                        itemClassName
                      )}
                    >
                      {item}
                      {Symbol && <Symbol className={styles.symbol} />}
                    </span>
                  );
                })}
            </span>
          );
        })}
      </div>
    </div>
  );
};
