export const getInternalLinkPath = reference => {
  if (reference && reference.content && reference.content.main) {
    if (reference.content.main.slug.current === "homepage") {
      return `/`;
    } else if (reference._type === "collection") {
      return `/collections/${reference.content.main.slug.current}`;
    } else if (reference._type === "product") {
      return `/products/${reference.content.main.slug.current}`;
    } else if (reference._type === "historyLesson") {
      return `/history-lessons/${reference.content.main.slug.current}`;
    } else {
      return `/${reference.content.main.slug.current}`;
    }
  } else {
    return `/`;
  }
};
