import { useStaticQuery, graphql } from "gatsby";
export const useFilterData = () => {
  const { filterSettings, tags, artists } = useStaticQuery(
    graphql`
      query FilterQuery {
        filterSettings: sanityFilterSettings {
          enableTagFilters
          tagFilterHeading
          enableArtistFilters
          artistFilterHeading
        }
        tags: allSanityTag(sort: { order: ASC, fields: order }) {
          edges {
            node {
              _id
              slug {
                current
              }
              title
            }
          }
        }
        artists: allSanityArtist(sort: { order: ASC, fields: order }) {
          edges {
            node {
              _id
              slug {
                current
              }
              name
            }
          }
        }
      }
    `
  );

  return {
    filterSettings,
    filterTags: {
      work: tags.edges,
      artists: artists.edges,
    },
  };
};
