import React from "react";
import cx from "classnames";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";
import {
  useToggleInstrument,
  useToggleContact,
  useSetActiveTags,
  useStoreContext,
} from "src/context/siteContext";

import styles from "./menu.module.css";

export const HeaderMenu = ({ className }) => {
  const location = useLocation();
  const { instrumentIsOpen } = useStoreContext();
  const toggleInstrument = useToggleInstrument();
  const toggleContact = useToggleContact();
  const setActiveTags = useSetActiveTags();

  const closeInstrument = () => {
    if (instrumentIsOpen) {
      toggleInstrument();
    }
  };

  return (
    <ul className={cx(styles.headerMenu, className)}>
      <li className={cx(styles.headerMenuItem)}>
        <Link
          className={styles.headerMenuLink}
          to="/"
          onClick={() => {
            setActiveTags([]);
            closeInstrument();
          }}
          getProps={({ isPartiallyCurrent }) => {
            return isPartiallyCurrent &&
              location?.pathname != "/about" &&
              !instrumentIsOpen
              ? {
                  className: cx(styles.headerMenuLink, styles.active),
                }
              : {};
          }}
        >
          <span className="heading5">Our Work</span>
        </Link>
      </li>
      <li className={cx(styles.headerMenuItem)}>
        <Link
          className={styles.headerMenuLink}
          to="/about"
          onClick={() => {
            closeInstrument();
          }}
          activeClassName={instrumentIsOpen ? "" : styles.active}
        >
          <span className="heading5">Who We Are</span>
        </Link>
      </li>
      <li className={cx(styles.headerMenuItem)}>
        <button
          className={styles.headerMenuButton}
          onClick={() => toggleContact()}
        >
          <span className="heading5">Contact</span>
        </button>
      </li>
    </ul>
  );
};
