import React, { useState, useEffect, useContext } from "react";
import isTouchEnabled from "../utils/isTouchEnabled";

const initialStoreState = {
  instrumentIsOpen: false,
  instrumentAudioIsOn: false,
  menuIsOpen: false,
  filterIsOpen: false,
  contactIsOpen: false,
  affirmationsIsOpen: false,
  page: undefined,
  activeTags: [],
  customCursorActive: false,
  customCursorType: "",
};

const StoreContext = React.createContext({
  store: initialStoreState,
  setStore: () => null,
});

const StoreContextProvider = ({ children }) => {
  const [store, setStore] = useState(initialStoreState);
  const [initStore, setInitStore] = useState(false);

  useEffect(() => {
    if (initStore === false) {
      setInitStore(true);
    }
  }, [store, setStore, initStore]);

  return (
    <StoreContext.Provider
      value={{
        store,
        setStore,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

function useStoreContext() {
  const { store } = useContext(StoreContext);
  return store;
}

function useSetPage() {
  const { setStore } = useContext(StoreContext);
  async function setPage(page) {
    setStore(prevState => {
      return { ...prevState, page };
    });
  }
  return setPage;
}

function useToggleMenu() {
  const {
    store: { menuIsOpen },
    setStore,
  } = useContext(StoreContext);

  function toggleMenu() {
    setStore(prevState => {
      return { ...prevState, menuIsOpen: !menuIsOpen };
    });
  }

  return toggleMenu;
}

function useToggleContact() {
  const {
    store: { contactIsOpen },
    setStore,
  } = useContext(StoreContext);

  function toggleContact() {
    setStore(prevState => {
      return { ...prevState, contactIsOpen: !contactIsOpen };
    });
  }

  return toggleContact;
}

function useToggleAffirmations() {
  const {
    store: { affirmationsIsOpen },
    setStore,
  } = useContext(StoreContext);

  function toggleAffirmations() {
    setStore(prevState => {
      return { ...prevState, affirmationsIsOpen: !affirmationsIsOpen };
    });
  }

  return toggleAffirmations;
}

function useToggleInstrument() {
  const {
    store: { instrumentIsOpen, instrumentAudioIsOn },
    setStore,
  } = useContext(StoreContext);

  const toggleInstrumentAudio = useToggleInstrumentAudio();

  function toggleInstrument() {
    if (instrumentAudioIsOn) {
      toggleInstrumentAudio();
    }
    setStore(prevState => {
      return { ...prevState, instrumentIsOpen: !instrumentIsOpen };
    });
  }

  return toggleInstrument;
}

function useCloseInstrument() {
  const { setStore } = useContext(StoreContext);

  function closeInstrument() {
    setStore(prevState => {
      return {
        ...prevState,
        instrumentIsOpen: false,
        instrumentAudioIsOn: false,
      };
    });
  }

  return closeInstrument;
}

function useOpenInstrument() {
  const { setStore } = useContext(StoreContext);

  function openInstrument() {
    setStore(prevState => {
      return {
        ...prevState,
        instrumentIsOpen: true,
        instrumentAudioIsOn: true,
      };
    });
  }

  return openInstrument;
}

function useToggleInstrumentAudio() {
  const {
    store: { instrumentAudioIsOn },
    setStore,
  } = useContext(StoreContext);

  function toggleInstrumentAudio() {
    setStore(prevState => {
      return { ...prevState, instrumentAudioIsOn: !instrumentAudioIsOn };
    });
  }

  return toggleInstrumentAudio;
}

function useToggleFilter() {
  const {
    store: { filterIsOpen },
    setStore,
  } = useContext(StoreContext);

  function toggleFilter() {
    setStore(prevState => {
      return { ...prevState, filterIsOpen: !filterIsOpen };
    });
  }

  return toggleFilter;
}

function useSetActiveTags() {
  const { setStore } = useContext(StoreContext);

  function setActiveTags(activeTags) {
    setStore(prevState => {
      return {
        ...prevState,
        activeTags,
      };
    });
  }

  return setActiveTags;
}

function useEnableCustomCursor() {
  const isTouch = isTouchEnabled();
  const { setStore } = useContext(StoreContext);

  function enableCustomCursor(state) {
    // never enable custom cursor on touch devices
    if (isTouch) {
      return;
    }
    setStore(prevState => {
      return {
        ...prevState,
        customCursorActive: state,
      };
    });
  }

  return enableCustomCursor;
}

function useSetCustomCursorType() {
  const { setStore } = useContext(StoreContext);

  function setCustomCursorType(type) {
    setStore(prevState => {
      return {
        ...prevState,
        customCursorType: type,
      };
    });
  }

  return setCustomCursorType;
}

export {
  StoreContextProvider,
  useStoreContext,
  useSetPage,
  useToggleMenu,
  useToggleContact,
  useToggleAffirmations,
  useToggleInstrument,
  useCloseInstrument,
  useOpenInstrument,
  useToggleInstrumentAudio,
  useToggleFilter,
  useSetActiveTags,
  useEnableCustomCursor,
  useSetCustomCursorType,
};
