import React, { useState, useEffect } from "react";
import cx from "classnames";
import { Popper, Fade } from "@material-ui/core";
import { motion } from "framer-motion";
import { SocialLinks } from "src/components/social-links";
import { Ticker } from "src/components/ticker";
import { AffirmationsDrawer } from "src/components/affirmations-drawer";

import { useToggleAffirmations } from "src/context/siteContext";

import { useFooterData } from "src/hooks/useFooterData";
import { useAffirmationsData } from "src/hooks/useAffirmationsData";

import styles from "./footer.module.css";

const Blob = () => {
  const svgVariants = {
    rest: {
      scale: 1,
    },
    hover: {
      scale: 1.05,
      transition: {
        easing: "circInOut",
        repeat: Infinity,
        repeatType: "reverse",
        duration: 1.5,
      },
    },
  };
  const pathVariants = {
    rest: {
      d: `M15.1 43.4C-16.5 84.2 -0.699982 147.3 83.3 113.3C138.2 91.1 165.7 125.8 239.5 128.8C295.6 131.1 306.7 105.5 391.8 106.5C471.2 107.4 476.9 137.6 532.2 137.6C598.4 137.6 584.2 89.9 680.5 119.2C747.7 139.6 738.5 -18 638 17.2C568.8 41.5 545.1 -21.6 466 11.4C408.2 35.5 376.8 22.9 325.6 7.49997C251.4 -14.8 221.6 19.7 176.3 22.1C138.7 24 132.6 8.29997 104.1 4.59997C67.5 -0.300026 34.9 17.9 15.1 43.4Z`,
    },
    hover: {
      d: `M16.7 27.5C-16 53.2 -4.99999 131.1 87.7 126.5C147.7 123.5 173 104.4 247.7 107.5C304.4 109.8 347.8 144.3 427.8 136.4C507.7 128.5 511.8 106.8 567.7 105.5C623.7 104.2 661.1 154.2 709.4 96.8C757.7 39.4 673.6 -10.3 602.7 12.5C546.7 30.5 505 25.1 455 8.50002C394.7 -11.5 346.2 9.40002 323.7 14.5C257.7 29.5 240.5 1.10002 194.7 3.50002C156.7 5.50002 135.7 24.5 106.7 22.5C75.8 20.4 44.7 5.50002 16.7 27.5Z`,
      transition: {
        easing: "circInOut",
        repeat: Infinity,
        repeatType: "reverse",
        duration: 1.2,
      },
    },
  };
  return (
    <motion.div whileHover="hover">
      <motion.svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 731.94 141.7"
        variants={svgVariants}
      >
        <motion.path
          variants={pathVariants}
          d="M15.1 43.4C-16.5 84.2 -0.699982 147.3 83.3 113.3C138.2 91.1 165.7 125.8 239.5 128.8C295.6 131.1 306.7 105.5 391.8 106.5C471.2 107.4 476.9 137.6 532.2 137.6C598.4 137.6 584.2 89.9 680.5 119.2C747.7 139.6 738.5 -18 638 17.2C568.8 41.5 545.1 -21.6 466 11.4C408.2 35.5 376.8 22.9 325.6 7.49997C251.4 -14.8 221.6 19.7 176.3 22.1C138.7 24 132.6 8.29997 104.1 4.59997C67.5 -0.300026 34.9 17.9 15.1 43.4Z"
        />
      </motion.svg>
    </motion.div>
  );
};

export const Footer = () => {
  const data = useFooterData();
  const { affirmations } = useAffirmationsData();
  const toggleAffirmations = useToggleAffirmations();
  const { footer, contact, social } = data;
  const [creditsOpen, setCreditsOpen] = useState(false);
  const [creditsAnchorEl, setCreditsAnchorEl] = React.useState(null);
  const handleCreditsClick = (event) => {
    setCreditsAnchorEl(creditsAnchorEl ? null : event.currentTarget);
    setCreditsOpen(!creditsOpen);
  };

  const consolidatedAffirmations = affirmations.reduce(
    (consolidatedArray, section) => {
      return consolidatedArray.concat(section.affirmations);
    },
    []
  );

  return (
    <div className={styles.footer}>
      <div className={styles.main}>
        <a
          href={`mailto:${contact.contactEmail}`}
          className={styles.workWithUs}
        >
          <Blob />
          <h2 className={cx("heading9 c-black", styles.workWithUsCopy)}>
            {footer.workWithUs}
          </h2>
        </a>
        <div className={styles.details}>
          <div className={styles.contactDetails}>
            <h4 className={cx("subheading2", styles.contactSubheading)}>
              New Business &amp; General Inquiries
            </h4>
            <span className="block">{contact.contactName}</span>
            <a
              className={cx("block", styles.contactLink)}
              href={`mailto:${contact.contactEmail}`}
            >
              {contact.contactEmail}
            </a>
            <a
              className={cx("block", styles.contactLink)}
              href={`tel:${contact.contactPhoneNumber}`}
            >
              {contact.contactPhone}
            </a>
          </div>
          <div className={styles.socialLinksContainer}>
            <h4 className={cx("subheading2", styles.socialSubheading)}>
              Connect with us
            </h4>
            <SocialLinks className={styles.socialLinks} social={social} />
          </div>
        </div>
      </div>
      <div className={styles.affirmations}>
        <h4 className={cx("subheading2", styles.affirmationSubheading)}>
          Daily Affirmations
        </h4>
        <button className={cx("reset")} onClick={toggleAffirmations}>
          <Ticker
            className={cx("body", styles.affirmationsTicker)}
            items={consolidatedAffirmations}
            randomSymbols={false}
          />
        </button>
      </div>
      <div className={styles.copywriteCredits}>
        <p className={cx("caption3")}>
          Copyright Space Pack LLC {new Date().getFullYear()}
        </p>
        <div className={styles.creditsButtonContainer}>
          <button
            type="button"
            className={cx("reset", styles.creditsButton)}
            onClick={handleCreditsClick}
          >
            <span className="caption3">Site Credits</span>
          </button>
        </div>
        <Popper
          open={creditsOpen}
          anchorEl={creditsAnchorEl}
          transition
          placement="top-start"
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={350}>
              <div className={styles.creditsTooltip}>
                <p className="caption3">
                  Design by{" "}
                  <a
                    href="https://butterstudio.co/"
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    Butter Studio
                  </a>
                </p>
                <p className="caption3">
                  Code by{" "}
                  <a
                    href="https://firstchild.co/"
                    target="_blank"
                    rel="noreferrer nofollow"
                  >
                    First Child
                  </a>
                </p>
              </div>
            </Fade>
          )}
        </Popper>
      </div>
      <AffirmationsDrawer />
    </div>
  );
};
