import { useStaticQuery, graphql } from "gatsby";

export const useAffirmationsData = () => {
  const { sanityAffirmations } = useStaticQuery(
    graphql`
      query AffirmationsQuery {
        sanityAffirmations {
          affirmationCategories: _rawAffirmationCategories(
            resolveReferences: { maxDepth: 4 }
          )
        }
      }
    `
  );

  const consolidatedAffirmations = sanityAffirmations.affirmationCategories.reduce(
    (consolidatedArray, section) => {
      return consolidatedArray.concat(section.affirmations);
    },
    []
  );

  return {
    affirmations: sanityAffirmations.affirmationCategories,
    consolidatedAffirmations: consolidatedAffirmations,
  };
};
