import { useStaticQuery, graphql } from "gatsby";
export const useFooterData = () => {
  const { sanityFooter, sanityContact, sanitySiteGlobal } = useStaticQuery(
    graphql`
      query FooterQuery {
        sanityFooter {
          workWithUs
        }
        sanityContact {
          contactName
          contactEmail
          contactPhone
          contactPhoneNumber
        }
        sanitySiteGlobal {
          content {
            social {
              facebook
              instagram
              twitter
              vimeo
            }
          }
        }
      }
    `
  );

  return {
    footer: sanityFooter,
    contact: sanityContact,
    social: sanitySiteGlobal.content.social,
  };
};
