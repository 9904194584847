import React from "react";
import { Link as GatsbyLink } from "gatsby";
import { getInternalLinkPath } from "src/utils/getInternalLinkPath";

export const Link = ({
  className,
  activeClassName = "active",
  type,
  link,
  to,
  href,
  ariaLabel,
  onClick,
  onMouseEnter,
  onMouseLeave,
  onMouseOver,
  children,
}) => {
  switch (type) {
    case "internalLink":
      const target = to
        ? to
        : link &&
          link.internalLink &&
          getInternalLinkPath(link.internalLink.link);

      return (
        <GatsbyLink
          type={type}
          className={className}
          activeClassName={activeClassName}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          to={target}
          aria-label={ariaLabel}
        >
          {children}
        </GatsbyLink>
      );
    case "externalLink":
      return (
        <a
          className={className}
          onClick={onClick}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onMouseOver={onMouseOver}
          href={
            href ? href : link && link.externalLink && link.externalLink.link
          }
          target={
            link && link.externalLink && !link.externalLink.targetBlank
              ? ""
              : "_blank"
          }
          rel="noopener noreferer"
          aria-label={ariaLabel}
        >
          {children}
        </a>
      );

    default:
      return null;
  }
};
