import React, { useEffect, useRef } from "react";
import cx from "classnames";
import { useMatch } from "@reach/router";
import { navigate } from "gatsby";
import { motion } from "framer-motion";
import { ThemeProvider } from "@material-ui/core/styles";
import { Modal, Slide } from "@material-ui/core";
import { Backdrop } from "src/components/backdrop";
import ScrollLock from "react-scrolllock";
import { useFilterData } from "src/hooks/useFilterData";

import {
  useStoreContext,
  useToggleFilter,
  useSetActiveTags,
} from "src/context/siteContext";

import { ReactComponent as CloseX } from "src/images/svg/CloseX.svg";
import { ReactComponent as Viewing } from "src/images/svg/Viewing.svg";
import { ReactComponent as Work } from "src/images/svg/Work.svg";
import { ReactComponent as Artists } from "src/images/svg/Artists.svg";
import { ReactComponent as XSmall } from "src/images/svg/XSmall.svg";

import { theme } from "src/utils/createMuiTheme";
import * as styles from "./project-filter.module.css";

const FilterListItem = ({ tag, handleTagClick, style }) => {
  return (
    <motion.li
      layout
      initial={{ opacity: 0, scale: 0.75 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.5 }}
      className={styles.tagListItem}
    >
      <button
        onClick={() => handleTagClick(tag)}
        className={cx("reset tag", `tag--${style}`, styles.filterButton)}
      >
        <span>{tag.title || tag.name}</span>
        <XSmall />
      </button>
    </motion.li>
  );
};

export const ProjectFilter = ({ location }) => {
  const { filterTags, filterSettings } = useFilterData();
  const { filterIsOpen, activeTags } = useStoreContext();
  const toggleFilter = useToggleFilter();
  const setActiveTags = useSetActiveTags();

  const activeTagSlugs = activeTags && activeTags.map(tag => tag.slug.current);

  const matchCaseStudy = useMatch("/work/:project");
  const matchAbout = useMatch("/about/*");
  const matchPreview = useMatch("/preview/*");

  const ref = useRef();

  useEffect(() => {
    if (activeTags.length < 1) {
      // only navigate if not already here
      if (
        location.pathname != `/` &&
        !matchAbout &&
        !matchPreview &&
        !matchCaseStudy
      ) {
        navigate(`/`, {
          state: { activeTags: activeTags, shouldUpdateScroll: true },
        });
      }
      return;
    }

    const joinedActiveTags = activeTags.map(tag => tag.slug.current).join(`+`);

    // only navigate if not already here
    if (
      location.pathname != `/work/tagged/${joinedActiveTags}` &&
      !matchCaseStudy &&
      !matchAbout
    ) {
      navigate(`/work/tagged/${joinedActiveTags}`, {
        state: { activeTags: activeTags, shouldUpdateScroll: true },
        replace: true,
      });
    }
  }, [activeTags]);

  const handleTagClick = tag => {
    if (activeTagSlugs.includes(tag.slug.current)) {
      const newActiveTags = activeTags.filter(activeTag => {
        return activeTag.slug.current != tag.slug.current;
      });
      setActiveTags(newActiveTags);
    } else {
      setActiveTags([...activeTags, tag]);
    }
  };

  const filterVariants = {
    open: { width: `100%` },
    closed: { width: 260 },
  };

  return (
    <ThemeProvider theme={theme}>
      <Modal
        open={filterIsOpen}
        onClose={toggleFilter}
        closeAfterTransition={true}
        BackdropComponent={Backdrop}
        BackdropProps={{
          active: filterIsOpen,
          toggle: toggleFilter,
        }}
      >
        <Slide
          direction="left"
          in={filterIsOpen}
          timeout={{
            enter: 600,
            exit: 300,
          }}
        >
          <div className={styles.drawer} role="presentation">
            <button
              className={cx("reset", styles.closeButton)}
              onClick={toggleFilter}
            >
              <CloseX />
            </button>
            <ScrollLock>
              <div className={styles.drawerContent}>
                <div className={cx(styles.filter)}>
                  <div className={styles.filterUI}>
                    <h2 className={styles.filterTitle}>
                      <span className={cx("heading3")}>Filter Work</span>
                    </h2>
                    <div className={styles.filterSection}>
                      <motion.h4
                        layout
                        className={cx("subheading1", styles.filterSectionTitle)}
                      >
                        <Viewing /> Viewing
                      </motion.h4>

                      <motion.ul layout="position" className="mt1 f fw">
                        {activeTags &&
                          activeTags.map(tag => {
                            return (
                              <FilterListItem
                                key={tag.slug.current}
                                tag={tag}
                                handleTagClick={handleTagClick}
                                style="light"
                              />
                            );
                          })}
                        {!activeTags.length && <span>All</span>}
                      </motion.ul>
                    </div>
                    {filterSettings.enableTagFilters && (
                      <div className={styles.filterSection}>
                        <motion.h4
                          layout
                          className={cx(
                            "subheading1",
                            styles.filterSectionTitle
                          )}
                        >
                          <Work /> {filterSettings.tagFilterHeading}
                        </motion.h4>
                        <motion.ul layout className="mt1 f fw">
                          {filterTags.work &&
                            filterTags.work
                              .filter(
                                tag =>
                                  !activeTagSlugs.includes(
                                    tag.node.slug.current
                                  )
                              )
                              .map(tag => {
                                return (
                                  <FilterListItem
                                    key={tag.node.slug.current}
                                    tag={tag.node}
                                    handleTagClick={handleTagClick}
                                    style="dark"
                                  />
                                );
                              })}
                        </motion.ul>
                      </div>
                    )}

                    {filterSettings.enableArtistFilters && (
                      <div className={styles.filterSection}>
                        <motion.h4
                          layout
                          className={cx(
                            "subheading1",
                            styles.filterSectionTitle
                          )}
                        >
                          <Artists /> {filterSettings.artistFilterHeading}
                        </motion.h4>
                        <motion.ul layout className="mt1 f fw">
                          {filterTags.artists &&
                            filterTags.artists
                              .filter(
                                tag =>
                                  !activeTagSlugs.includes(
                                    tag.node.slug.current
                                  )
                              )
                              .map(artist => {
                                return (
                                  <FilterListItem
                                    key={artist.node.slug.current}
                                    tag={artist.node}
                                    handleTagClick={handleTagClick}
                                    style="dark"
                                  />
                                );
                              })}
                        </motion.ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </ScrollLock>
          </div>
        </Slide>
      </Modal>
    </ThemeProvider>
  );
};
