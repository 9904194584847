import React, { useState, useEffect, useRef } from "react";
import cx from "classnames";
import { HeaderLogo } from "./logo";
import { HeaderMenu } from "./menu";
import { MobileMenu } from "src/components/mobile-menu";
import { Loading } from "src/components/loading";
import { useWindowSize } from "src/hooks/useWindowSize";
import { useDocumentScrollThrottled } from "src/hooks/useDocumentScrollThrottled";

import styles from "./header.module.css";

export const Header = ({ location }) => {
  const [collapsedHeight, setCollapsedHeight] = useState(400);

  const logoRef = useRef();
  const menuRef = useRef();

  const [isMounted, setIsMounted] = useState(false);
  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (logoRef && menuRef) {
      setCollapsedHeight(
        logoRef.current.clientHeight + menuRef.current.clientHeight
      );
    }
  }, [logoRef, menuRef]);

  // DIY headroom functionality
  const [hideHeader, setHideHeader] = useState(false);
  const MINIMUM_SCROLL = 80;
  const TIMEOUT_DELAY = 0;

  useDocumentScrollThrottled(callbackData => {
    const { previousScrollTop, currentScrollTop } = callbackData;
    const isScrolledDown = previousScrollTop < currentScrollTop;
    const isMinimumScrolled = currentScrollTop > MINIMUM_SCROLL;

    setTimeout(() => {
      setHideHeader(isScrolledDown && isMinimumScrolled);
    }, TIMEOUT_DELAY);
  });

  return (
    <div className={styles.header} style={{ height: collapsedHeight }}>
      <div className={cx(styles.headerInner, hideHeader ? styles.hidden : "")}>
        <div ref={logoRef}>
          <HeaderLogo location={location} />
        </div>
        <div ref={menuRef} className={styles.headerMenu}>
          <HeaderMenu />
          <MobileMenu location={location} />
        </div>
      </div>
      <Loading isLoaded={isMounted} />
    </div>
  );
};
