import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import cx from "classnames";
import { SkipNavLink, SkipNavContent } from "@reach/skip-nav";
import { Header } from "src/components/header";
import { Footer } from "src/components/footer";
import { ContactDrawer } from "src/components/contact-drawer";
import { ProjectFilter } from "src/components/project-filter";
import { useStoreContext, useToggleInstrument } from "src/context/siteContext";
import { CustomCursor } from "src/components/custom-cursor";

import "@reach/skip-nav/styles.css";
import "src/styles/main.css";

const Layout = ({ children, location }) => {
  const { instrumentIsOpen } = useStoreContext();
  const toggleInstrument = useToggleInstrument();

  let renderHeader = true;
  if (location.state?.renderHeader != undefined) {
    renderHeader = location.state?.renderHeader;
  }
  let renderFooter = true;
  if (location.state?.renderFooter != undefined) {
    renderFooter = location.state?.renderFooter;
  }
  // console.log(`render header from layout: ${renderHeader}`);
  const [isClient, setClient] = useState(false);
  const [enableFocusOutlines, setEnableFocusOutlines] = useState(false);

  useEffect(() => {
    setClient(true);
    window && window.addEventListener("keydown", handleKeydown);
  }, []);

  const key = isClient ? `client` : `server`;

  const handleKeydown = e => {
    // Detect a keyboard user from a tab key press
    const isTabEvent = e.keyCode === 9;

    if (isTabEvent) {
      setEnableFocusOutlines(true);
    }
  };

  return (
    <>
      <Helmet
        title="YouTooCanWoo"
        htmlAttributes={{
          class: cx(enableFocusOutlines ? "" : "no-outline-on-focus"),
        }}
      />
      <SkipNavLink onClick={() => instrumentIsOpen && toggleInstrument()}>
        <span className="caption1">Skip to content</span>
      </SkipNavLink>
      <CustomCursor />
      <div>
        {renderHeader && <Header location={location} />}
        <SkipNavContent />
        <div
          className="page-container"
          key={key}
          aria-hidden={instrumentIsOpen}
        >
          <div className="page-content">{children}</div>
          {renderFooter && <Footer />}
        </div>
        <ProjectFilter location={location} />
        <ContactDrawer />
      </div>
    </>
  );
};

export default Layout;
