import React, { useState, useRef, useEffect } from "react";
import cx from "classnames";
import { AnimatePresence, motion } from "framer-motion";
import { useStoreContext } from "src/context/siteContext";
import { ReactComponent as PlayCursor } from "../../images/svg/PlayCursor.svg";
import { ReactComponent as PauseCursor } from "../../images/svg/PauseCursor.svg";
import { ReactComponent as PlusCursor } from "../../images/svg/PlusCursor.svg";

import * as styles from "./custom-cursor.module.css";

export const CustomCursor = () => {
  const { customCursorActive, customCursorType } = useStoreContext();

  const [cursorPosition, setCursorPosition] = useState({ x: -100, y: -100 });

  useEffect(() => {
    const onMouseMove = event => {
      // Get cursor position
      const x = event.clientX - 56;
      const y = event.clientY - 56;

      requestAnimationFrame(() => {
        positionCursor(x, y);
      });
    };

    const positionCursor = (x, y) => {
      setCursorPosition({ x, y });
    };

    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, []);

  return (
    <AnimatePresence>
      {customCursorActive && (
        <motion.div
          initial={{ opacity: 0, scale: 0.8 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.8 }}
          className={styles.cursor}
          style={{
            left: `${cursorPosition.x}px`,
            top: `${cursorPosition.y}px`,
          }}
        >
          {customCursorType === "play" && <PlayCursor />}
          {customCursorType === "pause" && <PauseCursor />}
          {customCursorType === "plus" && <PlusCursor />}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
